<script setup lang="ts">
import {useWp} from "~/composables/Redmonkey";
import {NuxtLink} from "#components";

const {isExternalLink} = useWp();

const props = defineProps({
  title: String,
  age: String,
  price: String,
  content: String,
  url: {
    type: String,
    default: ''
  }
})

const LinkComponent = isExternalLink(props.url) ? 'a' : NuxtLink;
</script>
<template>
  <div class="course-item">
    <component :is="url ? LinkComponent : 'div'" :to="url" :href="url">
      <div class="course-item-content">
        <h3 v-if="title">{{ title }}</h3>
        <h5 v-if="age">{{ age }}</h5>
        <h6 v-if="price">{{ price }}</h6>
        <div v-if="content" class="content" v-html="content"/>
        <client-only>
          <div class="button" v-if="url">
            <component :is="LinkComponent" :to="url" :href="url"/>
          </div>
        </client-only>
      </div>
    </component>
  </div>
</template>

<style lang="scss">
@import "assets/css/source/components/course_item";
</style>